// filter-set.js
// Filter set class that defines properties and methods for a set of filters.

import cloneDeep from 'lodash.clonedeep';
import { objectsEqual, removePrivateKeys } from '@/lib/compare';
import { getAutocompleteQueryValue, getLookerQueryString } from '@/lib/filter-methods';
import { Filter } from '@/lib/filter';
import FilterServices from '@/services/filters';
import store from '@/store';

export class FilterSet {
  constructor(obj) {
    if (obj) {
      Object.keys(obj).forEach((prop) => {
        if (obj[prop] instanceof Filter) {
          this[prop] = obj[prop];
        }
      });
    }
  }

  // Get object in format needed for dashboard filter query to send to API
  get dashboardQuery() {
    return getLookerQueryString(cloneDeep(this), true);
  }

  // Get filter ids for filters in filter set
  get filterIds() {
    return Object.keys(this);
  }

  // Get all filters in filter set that are not set to hidden
  get filtersCapableOf() {
    const filtersCapableOf = new FilterSet();
    const userCapabilities = store.getters.capabilities;
    this.filterIds.forEach((filterId) => {
      if (this[filterId].capabilities.some((cap) => userCapabilities.includes(cap))) {
        filtersCapableOf[filterId] = this[filterId];
      }
    });
    return filtersCapableOf;
  }

  // Get all filters in filter set that are not set to hidden
  get filtersNoHiddens() {
    const filtersNoHiddens = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (!this[filterId].hidden) {
        filtersNoHiddens[filterId] = this[filterId];
      }
    });
    return filtersNoHiddens;
  }

  // Get all filters in filter set that are not "option" type
  get filtersNoOptions() {
    const filtersNoOptions = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (this[filterId] && !this[filterId].isOption) {
        filtersNoOptions[filterId] = this[filterId];
      }
    });
    return filtersNoOptions;
  }

  // Get all filters in filter set that are "option" type
  get filterOptions() {
    const filterOptions = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (this[filterId] && this[filterId].isOption) {
        filterOptions[filterId] = this[filterId];
      }
    });
    return filterOptions;
  }

  // Get all filters in filter set that have default values
  get filtersWithDefaults() {
    const filtersWithDefaults = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (this[filterId].dashboardDefaultValue) {
        filtersWithDefaults[filterId] = cloneDeep(this[filterId]);
        filtersWithDefaults[filterId].value = filtersWithDefaults[filterId].dashboardDefaultValue;
      } else if (this[filterId].defaultValue) {
        filtersWithDefaults[filterId] = cloneDeep(this[filterId]);
        filtersWithDefaults[filterId].value = filtersWithDefaults[filterId].defaultValue;
      }
    });
    return filtersWithDefaults;
  }

  // Get all filters in filter set that are required to have a value
  get filtersWithRequiredValues() {
    const requiredFilters = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (this[filterId].required) {
        requiredFilters[filterId] = cloneDeep(this[filterId]);
      }
    });
    return requiredFilters;
  }

  // Get all filters in filter set that have values set by a user
  get filtersWithValues() {
    const filtersWithValues = new FilterSet();
    this.filterIds.forEach((filterId) => {
      if (this[filterId].value) {
        filtersWithValues[filterId] = cloneDeep(this[filterId]);
      }
    });
    return filtersWithValues;
  }

  // Determine if filter set contains any filters or not
  get isEmpty() {
    return this.filterIds.length === 0;
  }

  // Get filter query string needed for Looker report URL
  get lookerQuery() {
    return getLookerQueryString(cloneDeep(this), false);
  }

  // Get filter set object in format needed to store via API
  get saveFormat() {
    const saveFormat = {};
    this.filterIds.forEach((filterId) => {
      saveFormat[filterId] = cloneDeep(this[filterId].value);
      saveFormat[filterId]._type = cloneDeep(this[filterId].type);
      saveFormat[filterId]._displayName = cloneDeep(this[filterId].displayName);
    });
    return saveFormat;
  }

  // Return object in format needed for autocomplete filter query to send to API
  autocompleteQuery(filterId, filterValues) {
    const autocompleteQuery = {};
    filterValues.filterIds.forEach((id) => {
      if (this[filterId].facets && this[filterId].facets.includes(id)) {
        const autocompleteSubject = this[id].autocompleteFieldOverride ||
        this[id].autocompleteSubject ||
        this[id].dashboardAutocompleteSubject;
        autocompleteQuery[autocompleteSubject] = getAutocompleteQueryValue(cloneDeep(filterValues[id]));
      }
    });
    if (this[filterId].autocompleteFieldOverride) {
      autocompleteQuery.field_override = cloneDeep(this[filterId].autocompleteFieldOverride);
    }
    if (this[filterId].autocompleteMappedField) {
      autocompleteQuery.mapped = cloneDeep(this[filterId].autocompleteMappedField);
    }
    return autocompleteQuery;
  }

  // Return filter ids for filters that depend on passed filter id
  facetedFilterIds(facetId) {
    const facetedFilterIds = this.filterIds.filter((filterId) => this[filterId].facets &&
      this[filterId].facets.includes(facetId));
    return facetedFilterIds;
  }

  // Determine if each of this filter set's filters' values match the passed filter set's filters' values
  filtersEqual(comparedFilters) {
    return [...this.filterIds, ...comparedFilters.filterIds]
      .every((filterId) => this[filterId] &&
        comparedFilters[filterId] &&
        objectsEqual(this[filterId].value, comparedFilters[filterId].value, removePrivateKeys));
  }

  filtersDiff(comparedFilters) {
    return [...this.filterIds, ...comparedFilters.filterIds].filter((filterId) => !this.filterIds.includes(filterId));
  }

  // Save filter set by sending a request to API
  saveFilterSet() {
    return FilterServices.postFilterSet(this.saveFormat);
  }
}

export default {
  FilterSet,
};
