<template>
  <v-app
    :class="{
      'menus-loading': !tenantSwitchComplete,
    }"
    class="w-100 h-100"
    fixed
    @click="(event) => clickAway(event)">
    <SideMenu v-if="loggedIn && tenantSwitchComplete && !tenantReauth"/>
    <v-app-bar
      v-if="loggedIn && tenantSwitchComplete"
      class="bg-primary elevation-0"
      height="48"
      absolute
      dense>
      <v-app-bar-title>
        {{ page }}
      </v-app-bar-title>
      <v-spacer/>
      <TenantSelect/>
      <div
        v-if="config.status"
        class="mr-2">
        <v-btn
          class="alerts-toggle"
          color="#edbc0e"
          size="35"
          variant="text"
          icon
          @click="expandAlerts = !expandAlerts">
          <v-icon icon="mdi-information-outline"/>
        </v-btn>
      </div>
      <div class="mr-2">
        <v-btn
          class="profile-toggle"
          color="#ebecec"
          size="35"
          variant="text"
          icon
          @click="expandProfile = !expandProfile">
          <v-icon icon="mdi-account"/>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main 
      class="inner-wrapper h-100"
      :class="tenantReauth ? 'w-100' : 'w-80'"
      >
      <v-progress-circular
        v-if="!tenantSwitchComplete"
        indeterminate
        class="text-primary loader app-loader"/>
      <div
        v-else-if="loggedIn"
        class="inner-wrapper router-wrapper h-100 w-100"
        >
        <div class="h-100 w-100">
          <router-view v-slot="{ Component }">
            <keep-alive :exclude="['LandingPage', 'AnalyticsRouter']">
              <component :is="Component"/>
            </keep-alive>
            <component
              :is="Component"
              v-if="Component === 'AnalyticsRouter'"/>
          </router-view>
        </div>
      </div>
      <div
        v-else
        class="inner-wrapper router-wrapper h-100 w-100"
        :class="tenantReauth ? 'w-80' : 'w-100'">
        <router-view/>
      </div>
    </v-main>
    <v-snackbar
      v-model="snack.show"
      :location="true ? 'top' : undefined">
      {{ snack.text }}
      <template #actions="{ attrs }">
        <v-btn
          v-bind="attrs"
          variant="text"
          class="text-secondary"
          @click="snack.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div
      v-if="expandProfile"
      class="profile-menu">
      <button
        class="profile-btn"
        @click="routeProfile">
        Profile
      </button>
      <button
        class="profile-btn"
        @click="logout">
        Log Out
      </button>
    </div>
    <AlertsCard
      v-if="expandAlerts"
      :title="config.status.title"
      :message="config.status.message"
      icon="mdi-alert"/>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { objectsEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';

import AlertsCard from './components/notification/AlertsCard/AlertsCard.vue';
import AnalyticsRouter from './components/analytics/AnalyticsRouter.vue';
import SideMenu from './components/SideMenu.vue';
import TenantSelect from './components/top-nav/TenantSelect/TenantSelect.vue';

export default {
  name: 'App',
  components: {
    AlertsCard,
    AnalyticsRouter,
    SideMenu,
    TenantSelect,
  },
  data() {
    return {
      expandProfile: false,
      expandAlerts: false,
    };
  },
  computed: {
    page() {
      let title;
      if (this.$route.meta.analyticsMenu) {
        if (this.subModule) {
          title = `${this.$route.meta.navbarTitle}: ${this.vendor || ''} ${this.subModule}`;
        } else {
          title = `${this.$route.meta.navbarTitle}`;
        }
      } else {
        title = this.$route.matched.reduce((result, route) => result || route.meta.navbarTitle, null);
      }
      return title || 'InfusionWare®';
    },
    loggedIn() {
      return (this.user !== null);
    },
    ...mapGetters([
      'config',
      'debugUser',
      'defaultTenant',
      'menuNavigationStart',
      'menuNavigationEnd',
      'subModule',
      'tabNavigationStart',
      'tabNavigationEnd',
      'tenant',
      'tenants',
      'tenantReauth',
      'tenantSwitchComplete',
      'user',
      'vendor',
      'snack',
    ]),
  },
  watch: {
    $route: {
      handler(to, from) {
        const now = moment();
        this.setCurrentPath();
        let loggingData;
        if (to && from) {
          if (
            (to.meta.analyticsMenu && !objectsEqual(from.params, to.params)) ||
            from.name !== to.name
          ) {
            const { params } = from;
            const menuTitle = from.meta.displayName ? from.meta.displayName.toLowerCase().replace(/ |-/g, '_') : null;
            let menuName;
            if (params && params.id) {
              menuName = menuTitle ? `${menuTitle}.${params.id.replace(/ |-/g, '_')}` : null;
            } else {
              menuName = menuTitle;
            }
            const menuData = {
              timestamp: now,
              menu: menuName,
            };
            this.setMenuNavigationStart(menuData);
            this.setTabNavigationStart({});
            this.setTabNavigationEnd({});
          }
        }
        // Log initial page load
        if (!from) {
          loggingData = new EventLog({
            event: 'page.load',
          });
          this.$services.users.postTrackingLog(loggingData);
        } else if (to.meta.displayName && !to.meta.analyticsMenu && to.name !== 'LandingPage') {
          loggingData = new EventLog({
            event: 'navigate',
          });
          this.$services.users.postTrackingLog(loggingData);
        }
      },
      deep: true,
      immediate: true,
    },
    debugUser: {
      handler() {
        if (this.debugUser) {
          const _log = console.log; //eslint-disable-line
          const _warn = console.warn; //eslint-disable-line
          const _error = console.error; //eslint-disable-line
          console.log = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'log',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _log.apply(console, args);
          };
          console.warn = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'warn',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _warn.apply(console, args);
          };
          console.error = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'error',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _error.apply(console, args);
          };
          window.onclick = (e) => {
            const loggingData = new EventLog({
              event: 'debug.click_event',
              target: e.target,
            });
            this.$services.users.postTrackingLog(loggingData);
          };
        }
      },
    },
    menuNavigationEnd: {
      handler() {
        if (
          this.menuNavigationStart && this.menuNavigationEnd &&
          'timestamp' in this.menuNavigationStart && 'timestamp' in this.menuNavigationEnd
        ) {
          const loggingData = new EventLog({
            event: 'metric.track_menu_navigation',
            milliseconds: this.menuNavigationEnd.timestamp.diff(this.menuNavigationStart.timestamp),
            startMenu: this.menuNavigationStart.menu,
            endMenu: this.menuNavigationEnd.menu,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.setMenuNavigationStart({});
          this.setMenuNavigationEnd({});
        }
      },
      immediate: true,
    },
    tabNavigationEnd: {
      handler() {
        if (
          this.tabNavigationStart && this.tabNavigationEnd &&
          'timestamp' in this.tabNavigationStart && 'timestamp' in this.tabNavigationEnd
        ) {
          const loggingData = new EventLog({
            event: 'metric.track_tab_navigation',
            milliseconds: this.tabNavigationEnd.timestamp.diff(this.tabNavigationStart.timestamp),
            startTab: this.tabNavigationStart.tab,
            endTab: this.tabNavigationEnd.tab,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.setTabNavigationStart({});
          this.setTabNavigationEnd({});
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.logPageClose);
  },
  methods: {
    logPageClose() {
      const loggingData = new EventLog({
        event: 'page.close',
      });
      this.$services.users.postTrackingLog(loggingData);
    },
    routeProfile() {
      this.expandProfile = false;
      this.$router.push({ path: '/profile', query: false });
    },
    clickAway(event) {
      if (this.expandProfile &&
        !event.target.classList.contains('profile-toggle') &&
        !event.target.classList.contains('mdi-account')) {
        this.expandProfile = false;
      }
      if (this.expandAlerts &&
        !event.target.classList.contains('mdi-information-outline') &&
        !event.target.classList.contains('mdi-alert')) {
        this.expandAlerts = false;
      }

    },
    logout() {
      this.expandProfile = false;
      this.$router.push({ name: 'LogoutPortal' });
    },
    ...mapActions([
      'setCurrentPath',
      'setMenuNavigationEnd',
      'setMenuNavigationStart',
      'setTabNavigationEnd',
      'setTabNavigationStart',
      'updateTenant',
    ]),
  },
};
</script>

<style lang="scss">
@import '@/sass/main.scss';
@import '@/sass/icons.scss'; // Icons are loaded separately due to relative namespacing.

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Menu for preferences and logout
.profile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $bainbridge-blue;
  padding: 4px;
  top: 48px;
  bottom: calc(100% - 120px);
  left: calc(100% - 200px);
  right: 0px;
  background-color: $bainbridge-gray-light;
  z-index: 3;
  border-radius: 0px 0px 4px 4px;
}

.profile-btn {
  width: 198px;
  height: 48px;

  &:hover {
    background-color: darken($bainbridge-gray-light, 5);
  }
}

.navbar-title {
  font-weight: normal;
  font-size: 20px;
}

.inner-wrapper {
  position: absolute;
  width: calc(100% - 80px);
}

.router-wrapper {
  padding-bottom: 2rem;
  overflow-y: auto;
}

.app-loader {
  height: 100% !important;
}

.menus-loading {
  cursor: progress !important;
}

.v-snack {
  padding-top: 0px !important;

  .v-snack__content {
    margin: 6px;
    font-size: 14px;
  }

  .v-snack__action {
    margin-right: 0px !important;
  }
}

.tenant-select {
  display: inline-block;
  font-size: 16px;
  text-align: right;
  cursor: pointer;
  &option:hover {
    background-color: lighten($bainbridge-blue, 5);
  }
  &:focus {
    outline: none;
  }
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}
</style>
