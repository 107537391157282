<template>
  <div class="h-100">
    <v-btn
      size="small"
      absolute
      color="primary"
      class="ma-auto expand-button"
      :class="{'expanded': !collapsed}"
      icon
      @click.stop="collapsed = !collapsed">
      <v-icon
        :icon="collapsed ? 'mdi-chevron-right' : 'mdi-chevron-left'"/>
    </v-btn>
    <v-navigation-drawer
      :rail="collapsed"
      :rail-width="80"
      :width="255"
      class="elevation-5 side-menu bainbridge-gray-light pt-4 px-0 pb-0 align-center justify-center"
      absolute
      permanent
      floating>
      <img
        class="bainbridge-name-horiz"
        src="~@/assets/images/BH-Alt.svg"
        @click="redirectHome()">
      <v-list
        :class="{'align-center': collapsed}"
        class="menu-list pt-2 flex-1"
        density="compact">
        <NavMenu
          v-for="item in menus"
          :key="`${item.title}-${item.subs.length}`"
          :expanded="!collapsed"
          :icon="item.icon"
          :icon-style="item.icon_style"
          :sub-menus="item.subs"
          :title="item.title"
          :url="item.url"/>
        <v-spacer class="flex-1"/>
        <NavMenu
          v-for="item in navbarItems"
          :key="item.title"
          :expanded="!collapsed"
          :icon="item.icon"
          :name="item.name"
          :title="item.title"
          :url="item.url"/>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import moment from 'moment';

import { mapActions, mapGetters } from 'vuex';
import { objectsEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';
import NavMenu from './utils/NavMenu.vue';

export default {
  name: 'SideMenu',
  components: {
    NavMenu,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    ...mapGetters([
      'menus',
      'navbarItems',
    ]),
  },
  methods: {
    redirectHome() {
      this.$router.push({ name: 'LandingPage' });
    },
  },
};
</script>

<style lang="scss">
@import '../sass/colors.scss';

.v-navigation-drawer.side-menu {
  box-shadow: none;
  z-index: 3;
  .v-navigation-drawer__content {
    display: flex;
    flex-flow: column;
  }
  img.bainbridge-name-horiz {
    height: 35px;
    width: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .menu-list {
    display: flex;
    flex-flow: column;
    align-items: flex-start !important;
    .v-btn {
      height: 45px;
      width: 45px;
    }
    .menu-icon {
      font-size: 30px;
    }
    .mdi-alert {
      color: white !important;
      font-size: 12px !important;
      margin-top: -1px;
    }
  }
}
.expand-button {
  display: absolute;
  height: 30px !important;
  width: 30px !important;
  top: 85% !important;
  left: 65px!important;
  border-width: 2px !important;
  color: $bainbridge-gray-light !important;
  z-index: 1007;
  .v-btn__content {
    height: 100%;
    .v-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      line-height: -moz-block-height;
    }
  }
}
.expanded {
  left: 240px;
  transform: translateX(175px);
}
</style>
