import * as Sentry from '@sentry/browser';

import { INTERNAL_IDENTITY_PROVIDER } from '@/lib/identity-methods';
import IdentityService from '@/services/identity';
import router from '@/router/index';

export default {
  state: {
    accessToken: null,
    capabilities: [],
    claimsToken: null,
    debugUser: false,
    identityProvider: null,
    identityResetComplete: true,
    inactivityTimer: null,
    multiFactor: null,
    sessionTimer: null,
    tenantReauth: false,
    user: null,
  },
  mutations: {
    RESET_USER(state) {
      state.accessToken = null;
      state.capabilities = [];
      state.claimsToken = null;
      state.debugUser = false;
      state.identityProvider = null;
      state.identityResetComplete = true;
      state.multiFactor = null;
      if (state.sessionTimer) {
        clearInterval(state.sessionTimer);
      }
      if (state.inactivityTimer) {
        clearInterval(state.inactivityTimer);
      }
      state.sessionTimer = null;
      state.inactivityTimer = null;
      state.user = null;
      sessionStorage.removeItem('claimsToken');
      localStorage.removeItem('claimsToken');
      localStorage.removeItem('signinEmail');
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
    SET_CAPABILITIES(state, capabilities) {
      if (SENTRY_ENABLED) {
        Sentry.configureScope((scope) => {
          const userInfo = scope._user;
          userInfo.capabilities = capabilities;
          Sentry.setUser(userInfo);
        });
      }
      state.capabilities = capabilities;
    },
    SET_CLAIMS_TOKEN(state, claimsToken) {
      if (claimsToken) {
        sessionStorage.setItem('claimsToken', claimsToken);
        localStorage.setItem('claimsToken', claimsToken);
      } else {
        sessionStorage.removeItem('claimsToken');
        localStorage.removeItem('claimsToken');
      }
      state.claimsToken = claimsToken;
    },
    SET_DEBUG_USER(state, debugUser) {
      state.debugUser = debugUser;
    },
    SET_IDENTITY_PROVIDER(state, identityProvider) {
      state.identityProvider = identityProvider;
    },
    SET_IDENTITY_RESET_COMPLETE(state, status) {
      state.identityResetComplete = status;
    },
    SET_MULTI_FACTOR(state, multiFactor) {
      state.multiFactor = multiFactor;
    },
    SET_USER(state, user) {
      if (user.email) {
        state.user = user;
        if (SENTRY_ENABLED) {
          Sentry.setUser({
            email: user.email,
            id: user.id,
          });
        }
      }
    },
    SET_SESSION_TIMER(state, sessionTimer) {
      state.sessionTimer = sessionTimer;
    },
    SET_INACTIVITY_TIMER(state, inactivityTimer) {
      state.inactivityTimer = inactivityTimer;
    },
    SET_TENANT_REAUTH(state, reAuth) {
      state.tenantReauth = reAuth;
    },
  },
  actions: {
    setReauthenticateRequired({ commit }, reAuth) {
      commit('SET_TENANT_REAUTH', reAuth);
    },
    resetUser({ commit }) {
      commit('RESET_USER');
    },
    refreshAccessToken({
      commit, dispatch, rootGetters,
    }) {
      return new Promise((resolve, reject) => {
        try {
          rootGetters.app.config.globalProperties.$firebaseAuth.currentUser.getIdToken(true).then((accessToken) => {
            commit('SET_ACCESS_TOKEN', accessToken);
            resolve();
          }).catch(() => {
            dispatch('logout').then(() => window.location.replace('/'));
          });
        } catch {
          dispatch('logout').then(() => window.location.replace('/'));
        }
      });
    },
    refreshClaimsToken({
      commit, dispatch, rootGetters,
    }) {
      return new Promise((resolve, reject) => {
        if (!rootGetters.tenant) {
          IdentityService.getClaimsToken().then((response) => {
            dispatch('setClaimsToken', { claimsToken: response.claims_token }).then(() => {
              resolve();
            });
          }).catch(() => {
            dispatch('logout').then(() => window.location.replace('/'));
          });
        } else {
          IdentityService.getClaimsTokenWithTenant(rootGetters.tenant.id).then((response) => {
            dispatch('setClaimsToken', { claimsToken: response.claims_token }).then(() => {
              resolve();
            });
          }).catch(() => {
            dispatch('logout').then(() => window.location.replace('/'));
          });
        }
      });
    },
    resetIdentityTokens({
      commit, state, dispatch, rootGetters,
    }) {
      return new Promise((resolve, reject) => {
        if (state.identityResetComplete) {
          commit('SET_IDENTITY_RESET_COMPLETE', false);
          dispatch('abortRequests');
          try {
            dispatch('refreshAccessToken').then(() => {
              dispatch('refreshClaimsToken').then(() => {
                dispatch('resendRequests');
                commit('SET_IDENTITY_RESET_COMPLETE', true);
                resolve();
              });
            });
          } catch {
            dispatch('logout').then(() => window.location.replace('/'));
          }
        } else {
          resolve();
        }
      });
    },
    setAccessToken({ commit }, accessToken) {
      commit('SET_ACCESS_TOKEN', accessToken);
    },
    setCapabilities({ commit }, capabilities) {
      commit('SET_CAPABILITIES', capabilities);
    },
    setClaimsToken({ commit, dispatch }, options) {
      return new Promise((resolve, reject) => {
        const claimsToken = options ? options.claimsToken : null;
        const tenantId = options ? options.tenantId : null;
        if (tenantId) {
          IdentityService.getClaimsTokenWithTenant(tenantId).then((response) => {
            commit('SET_CLAIMS_TOKEN', response.claims_token);
            dispatch('setCapabilities', response.claims.capabilities);
            resolve();
          }).catch((error) => {
            dispatch('logout').then(() => window.location.replace('/'));
          });
        } else {
          commit('SET_CLAIMS_TOKEN', claimsToken);
          resolve();
        }
      });
    },
    setDebugUser({ commit }, debugUser) {
      commit('SET_DEBUG_USER', debugUser);
    },
    setIdentityProvider({ commit }, identityProvider) {
      commit('SET_IDENTITY_PROVIDER', identityProvider);
    },
    setMultiFactor({ commit }, multiFactor) {
      commit('SET_MULTI_FACTOR', multiFactor);
    },
    // Check inactivity of session
    setInactivitySessionTimer({ commit, dispatch }) {
      if (VUE_INACTIVITY_TIMEOUT_INTERVAL) {
        const inactivityTimer = setInterval(() => {
          IdentityService.getLastUserActivity().then((response) => {
            const { path } = router.currentRoute.value;
            if (response.status === 403) {
              dispatch('setReauthenticateRequired', true);
              dispatch('resetMenus');
              if (path !== '/reauthenticate') {
                router.push({ name: 'Reauthentication', query: { redirect: path.slice(1) } });
              }
            }
          });
        }, VUE_INACTIVITY_TIMEOUT_INTERVAL);
        commit('SET_INACTIVITY_TIMER', inactivityTimer);
      }
    },
    setSessionTimer({ commit, dispatch }) {
      // Refresh Google IdP access token, then Claims token, every 55 minutes
      const sessionTimer = setInterval(() => {
        dispatch('refreshAccessToken').then(() => dispatch('refreshClaimsToken'));
      }, 3300000);
      commit('SET_SESSION_TIMER', sessionTimer);
    },
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,
    capabilities: (state) => state.capabilities,
    claimsToken: (state) => state.claimsToken,
    debugUser: (state) => state.debugUser,
    identityProvider: (state) => state.identityProvider,
    internalIdentityProvider: (state) => (
      state.user ? state.user.identity_provider === INTERNAL_IDENTITY_PROVIDER : null
    ),
    multiFactor: (state) => state.multiFactor,
    multiFactorRequired: (state, getters) => {
      if (state.user && getters.domainTenant && getters.domainTenant.mfa_required &&
        getters.internalIdentityProvider && !state.user.mfa_enabled) {
        return true;
      } else {
        return false;
      }
    },
    user: (state) => state.user,
    tenantReauth: (state) => state.tenantReauth,
    userHasCapability: (state, getters) => (cap) => (
      state.capabilities && getters.tenant && state.capabilities.includes(cap)
    ),
  },
};
