import {
  POST_METHOD, PUT_METHOD, DELETE_METHOD, Request,
} from '@/lib/http';

const baseURL = '/api/identity';

export default {
  getClaimsToken() {
    const request = new Request(`${baseURL}/claims_token`, {
      withoutClaims: true, preventAbort: true,
    });
    return request.send();
  },
  getClaimsTokenWithTenant(tenantId) {
    const request = new Request(`${baseURL}/claims_token/${tenantId}`, {
      withoutClaims: true, preventAbort: true,
    });
    return request.send();
  },
  getLastUserActivity() {
    // This will be updated to make an api request
    return new Promise((resolve) => {
      resolve({ status: 403 });
    });
  },
  getTenantProvider(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/provider`);
    return request.send();
  },
  getUserClaims() {
    const request = new Request(`${baseURL}/claims_token/commands/read`);
    return request.send();
  },
  getUserProvider(email) {
    const request = new Request(`${baseURL}/user/${email}/provider`, { withoutAuth: true, withoutClaims: true });
    return request.send();
  },
  postLogin() {
    const request = new Request(`${baseURL}/commands/login`, { method: POST_METHOD });
    return request.send();
  },
  postLogout() {
    const request = new Request(`${baseURL}/commands/logout`, { method: POST_METHOD });
    return request.send();
  },
  postTenantProvider(tenantId, createData) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/provider`, {
      body: createData, method: POST_METHOD, preventAbort: true,
    });
    return request.send();
  },
  putTenantProvider(tenantId, updateData) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/provider`, {
      body: updateData, method: PUT_METHOD, preventAbort: true,
    });
    return request.send();
  },
  deleteTenantProvider(tenantId) {
    const request = new Request(`${baseURL}/tenant/${tenantId}/provider`, {
      method: DELETE_METHOD, preventAbort: true,
    });
    return request.send();
  },
};
