import { createWebHashHistory, createRouter } from 'vue-router';

import AnalyticsRouter from '@/components/analytics/AnalyticsRouter.vue';
import DebugRouter from '@/components/debug/DebugRouter.vue';
import DebugPortal from '@/components/debug/DebugPortal.vue';
import { DrugInfo, DrugList, LibraryRouter } from '@/components/library/index';
import IntelligenceReports from '@/components/intelligence/IntelligenceReports.vue';
import UploadDashboard from '@/components/manual-upload/UploadDashboard.vue';
import LandingPage from '@/components/LandingPage.vue';
import LoginPortal from '@/components/LoginPortal.vue';
import Reauthentication from '@/components/reauth/Reauthentication.vue';
import LoginPortalSecurity from '@/components/LoginPortalSecurity.vue';
import LogoutPortal from '@/components/LogoutPortal.vue';
import PasswordReset from '@/components/PasswordReset.vue';
import SsoConfig from '@/components/user-admin/SsoConfig.vue';
import UserAdmin from '@/components/user-admin/UserAdmin.vue';
import UserConfig from '@/components/user-admin/UserConfig.vue';
import UserPortal from '@/components/user-admin/UserPortal.vue';
import UserProfile from '@/components/profile/UserProfile.vue';
import UserTesting from '@/components/debug/UserTesting.vue';

import {
  ANALYTICS_CAPABILITY,
  DEBUG_ADMIN_CAPABILITY,
  EMPLOYEE_CAPABILITY,
  INTELLIGENCE_CAPABILITY,
  SSO_CONFIG_CAPABILITY,
  USER_CONFIG_CAPABILITY,
} from '@/lib/identity-methods';
import store from '@/store/index';

const defaultPath = { name: 'LandingPage' };

const tryWhoami = async (next, obj, capabilities, redirect) => {
  const loginRequired = () => {
    store.dispatch('setAccessToken');
    store.dispatch('setClaimsToken');
    return next({
      path: '/signin',
      query: redirect,
    });
  };

  const { app } = store.getters;
  const { currentUser } = app.config.globalProperties.$firebaseAuth;
  if (currentUser) {
    return currentUser.getIdToken().then((idToken) => {
      store.dispatch('setAccessToken', idToken);

      const claimsToken = sessionStorage.getItem('claimsToken') || localStorage.getItem('claimsToken');
      if (claimsToken) {
        store.dispatch('setClaimsToken', { claimsToken });
      }
      return store.dispatch('login', claimsToken).then(() => {
        if (capabilities && !capabilities.some((cap) => store.getters.userHasCapability(cap))) {
          return next(defaultPath);
        }
        if (store.getters.multiFactorRequired) {
          return next({
            path: '/profile',
            query: obj.query,
          });
        }

        return next(obj);
      }).catch(loginRequired);
    }).catch(loginRequired);
  }
  return new Promise((resolve) => {
    resolve(loginRequired());
  });
};

const SentryTesting = () => import(/* webpackChunkName: "sentry" */ '@/components/debug/SentryTesting.vue');

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      meta: {
        requiresAuth: true,
        displayName: 'Landing Page',
      },
    },
    {
      path: '/resetPassword',
      name: 'PasswordReset',
      component: PasswordReset,
      meta: {
        displayName: 'Reset Password Portal',
      },
    },
    {
      path: '/signin',
      name: 'LoginPortal',
      component: LoginPortal,
      meta: {
        displayName: 'Login Portal',
      },
    },
    {
      path: '/signinSecurityScan',
      name: 'LoginPortalSecurity',
      component: LoginPortalSecurity,
      meta: {
        displayName: 'Login Portal for Security Scan',
      },
    },
    {
      path: '/reauthenticate',
      name: 'Reauthentication',
      component: Reauthentication,
      meta: {
        requiresAuth: true,
        navbarTitle: 'Reauthentication',
        displayName: 'Reauthentication',
      },
    },
    {
      path: '/logout',
      name: 'LogoutPortal',
      component: LogoutPortal,
      meta: {
        displayName: 'Logout Portal',
      },
    },
    {
      path: '/profile',
      name: 'UserProfile',
      component: UserProfile,
      meta: {
        requiresAuth: true,
        navbarTitle: 'Profile',
        displayName: 'Profile',
      },
    },
    {
      path: '/pumps/:id?',
      name: 'Pumps',
      props: false,
      component: AnalyticsRouter,
      meta: {
        requiresAuth: true,
        capabilities: [ANALYTICS_CAPABILITY],
        navbarTitle: 'Pumps',
        displayName: 'Pumps',
        analyticsMenu: true,
      },
    },
    {
      path: '/analytics/:id?',
      name: 'Analytics',
      props: false,
      component: AnalyticsRouter,
      meta: {
        requiresAuth: true,
        capabilities: [EMPLOYEE_CAPABILITY],
        navbarTitle: 'Portal Analytics',
        displayName: 'Analytics',
        analyticsMenu: true,
      },
    },
    {
      path: '/intelligence',
      name: 'Intelligence',
      component: IntelligenceReports,
      meta: {
        requiresAuth: true,
        capabilities: [INTELLIGENCE_CAPABILITY],
        navbarTitle: 'Infusion Intelligence',
        displayName: 'Intelligence',
      },
    },
    {
      path: '/file-upload',
      name: 'FileUpload',
      component: UploadDashboard,
      meta: {
        requiresAuth: true,
        navbarTitle: 'Manual File Upload',
        displayName: 'File Upload',
      },
    },
    {
      path: '/user-admin',
      component: UserAdmin,
      meta: {
        requiresAuth: true,
        capabilities: [USER_CONFIG_CAPABILITY, SSO_CONFIG_CAPABILITY],
        navbarTitle: 'User Administration',
        displayName: 'User Administration',
      },
      children: [
        {
          path: '',
          name: 'UserPortal',
          component: UserPortal,
          meta: {
            displayName: 'User Admin',
          },
        },
        {
          path: 'sso-config',
          name: 'SsoConfig',
          component: SsoConfig,
          meta: {
            displayName: 'Single Sign-On Config',
            capabilities: [SSO_CONFIG_CAPABILITY],
          },
        },
        {
          path: 'user-config',
          name: 'UserConfig',
          component: UserConfig,
          meta: {
            displayName: 'User Config',
            capabilities: [USER_CONFIG_CAPABILITY],
          },
        },
      ],
    },
    {
      path: '/debug',
      component: DebugRouter,
      meta: {
        requiresAuth: true,
        capabilities: [DEBUG_ADMIN_CAPABILITY],
        navbarTitle: 'Debug',
        displayName: 'Debug',
      },
      children: [
        {
          path: '',
          name: 'DebugPortal',
          component: DebugPortal,
          meta: {
            displayName: 'Debug',
          },
        },
        {
          path: 'sentry-testing',
          name: 'SentryTesting',
          component: SentryTesting,
          meta: {
            displayName: 'Sentry Testing',
          },
        },
        {
          path: 'user-testing',
          name: 'UserTesting',
          component: UserTesting,
          meta: {
            displayName: 'User Troubleshooting',
          },
        },
      ],
    },
    {
      path: '/library',
      component: LibraryRouter,
      meta: {
        requiresAuth: true,
        capabilities: ['library'],
        navbarTitle: 'Infusion Library',
        displayName: 'Library',
      },
      children: [
        {
          path: '',
          name: 'DrugList',
          component: DrugList,
          meta: {
            displayName: 'Library',
          },
        },
        {
          path: ':drug',
          name: 'DrugInfo',
          component: DrugInfo,
          meta: {
            displayName: 'Library',
          },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*', // Unrecognized path automatically pushes to default
      redirect: '/',
    },
    {
      path: '/:pathMatch(.*)', // Unrecognized path automatically pushes to default
      redirect: '/',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // NB: This is not real "auth", but is intended to provide
    // a decent experience when auth fails for a bookmarked route
    const { capabilities } = to.meta;
    const redirectQuery = window.location.hash.split('#/')[1];
    if (!store.getters.user) {
      return tryWhoami(next, { path: to.path, query: to.query }, capabilities, { redirect: redirectQuery });
    } else if (store.getters.multiFactorRequired && to.path !== '/profile') {
      if (from.path !== '/profile') {
        return next({
          path: '/profile',
          query: to.query,
        });
      }
    } else if (capabilities && !capabilities.some((cap) => store.getters.userHasCapability(cap))) {
      return next(defaultPath);
    }

    if (to.path === '/') {
      return next();
    } else if (store.getters.tenantReauth && to.path !== '/reauthenticate' && to.path !== '/logout') {
      return next({
        path: '/reauthenticate',
      });
    }
  }
  return next();
});

export default router;
